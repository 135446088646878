@import url('https://fonts.googleapis.com/css2?family=Jost&family=Quicksand&display=swap');

html, body {
    margin: 0;
    font-family: 'Quicksand', sans-serif;
    background-color: #1F4068;
    color: white;
}

.ff-jose {
    font-family: 'Jost', sans-serif ;
}

.font-big {
    font-family: 'Jost', sans-serif;
    font-size: 50px;
    font-weight: bolder;
    letter-spacing: 2px;
}

.bg-dark {
    background-color: #162447 !important;
}

.bg-blue {
    background-color: #1B1B2F;
}

.text-red {
    color: #E43F5A;
}

.text-black {
    color: #162447;
}

.dropdown-item:active {
    background-color: #1B1B2F !important;
}

.active {
    color: #E43F5A !important;
    background-color: #162447 !important;
    font-weight: 600;
    transition: transform 0.2s;
    transform: scale(1.1);
}

@keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
}

@keyframes slideInTop {
    0% {
      transform: translateY(-10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
    }
}

@keyframes slideInBottom {
    0% {
      transform: translateY(10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
    }
}

.slide-in-left {
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: slideInLeft;
}

.slide-in-top {
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: slideInTop;
}

.slide-in-bottom {
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: slideInBottom;
}

/* ===================== Transition ===================== */

.animate-charcter {
    background-image: linear-gradient(-225deg,#ffffff,#E43F5A,#E43F5A,#ffffff);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

/* ===================== Home ===================== */

.home-container {
    background-color: #1F4068;
    color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 25px;
}

.profile-img {
    border-radius: 50%;
    border: 5px solid #E43F5A;
    max-width: 300px;
    height: auto;
    margin-top: 50px;
}

.ls-2 {
    letter-spacing: 2px; 
}

/* ===================== Skills ===================== */

.mw-fc {
    max-width: fit-content;
}

/* ===================== My Work ===================== */

.custom-arrow {
    background: none;
    border: none;
}

.bold-arrow {
    color: #E43F5A;
    font-size: 2rem;
    font-weight: bold;
}

.project-image-container {
    width: 100%;
    height: 0;
    padding-bottom: 75%; /* This maintains a 4:3 aspect ratio. Adjust as needed. */
    position: relative;
    overflow: hidden;
    background-color: #f0f0f0; /* Light gray background for areas not covered by the image */
  }
  
  .project-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* This will fit the entire image within the container */
  }
/*   
  .project-image:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  } */
/* ===================== Resume ===================== */

li::marker {
    color: #E43F5A;
    font-size: 2em;
}

.blue-label {
    background-color: #1B1B2F;
    width: max-content;
}

.zoom-on-hover:hover {
    transition: transform 0.2s;
    transform: scale(1.1);
}

/* ===================== Portfolio ===================== */

.portfolio-link:hover {
    background-color: #E43F5A;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
}

.navbar .nav-link {
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;
}

.navbar .d-flex a {
    margin-left: 10px;
}

/* ===================== Resume Section ===================== */
.resume-section {
  padding: 2rem;
  background-color: #162447;
  color: white;
}

.resume .d-flex {
  justify-content: center;
}

.resume .react-pdf__Document {
  margin: 0 auto;
}

.resume-section .p-4 {
  margin-left: 15px;
  margin-right: 15px;
}
/* ===================== Contact Me ===================== */
.form-control {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
  }
  
  .form-control:focus {
    background-color: rgba(255, 255, 255, 0.2);
    border-color: #007bff;
    color: white;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .form-label {
    color: white;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }